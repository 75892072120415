import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../services/AuthContext'; // Use AuthContext

function UrlShortenerForm({ onShortenSuccess }) {
  const [originalUrl, setOriginalUrl] = useState('');
  const [shortUrl, setShortUrl] = useState('');
  const [error, setError] = useState('');
  const { currentUser } = useAuth(); // Use currentUser from AuthContext

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    setShortUrl('');
  
    // Include the Authorization header if user is logged in
    const config = currentUser ? {
      headers: { Authorization: `Bearer ${currentUser.token}` }
    } : {};
  
    try {
      const response = await axios.post(`${window.location.origin}/shorten`, {
        originalUrl,
      }, config);
      onShortenSuccess(response.data);
      setShortUrl(response.data.shortUrl);
    } catch (err) {
      // Use error response from the server if available
      setError(err.response?.data?.error || 'Failed to generate short URL. Please try again.');
      console.error(err);
    }
  };

  return (
    <div className="container-body">
      <form onSubmit={handleSubmit}>
        <label htmlFor="originalUrl">URL to shorten:</label>
        <input
          type="text"
          id="originalUrl"
          name="originalUrl"
          value={originalUrl}
          onChange={(e) => setOriginalUrl(e.target.value)}
          required
        />
        <button type="submit">Shorten URL</button>
      </form>
      {shortUrl && (
        <div>
          <p>Short URL:</p>
          <a href={`${window.location.origin}/${shortUrl}`} target="_blank" rel="noopener noreferrer">
            {window.location.origin}/{shortUrl}
          </a>
        </div>
      )}
      {error && <p className="error">{error}</p>}
    </div>
  );
}

export default UrlShortenerForm;
