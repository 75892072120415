import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className='container-body'>
        <h1>Privacy Policy</h1>
                <p>At <strong>gsgl.ink</strong> (hereafter referred to as "we," "us," or "our"), we respect your privacy and are committed to protecting it through our adherence to this Privacy Policy (this "Policy"). This Policy describes the types of information we may collect from you or that you may provide when you use our website located at gsgl.ink (the "Site") and our short URL redirection service ("Service"). Please read this Policy carefully to understand how we handle your information. By using or accessing the Site or Service, you agree to be bound by this Policy.</p>

                <h2>Information Collection and Use:</h2>
                <p>We collect and use information only as described in this Policy. We do not collect any personally identifiable information beyond standard information provided by your web browser, such as your IP address, operating system, and browser type. We also do not collect or store any data from user accounts beyond a username and email address. User passwords are stored using bcrypt hashing with salt values to ensure secure storage.</p>

                <h2>Cookies and Tracking Technologies:</h2>
                <p>We do not use cookies or other tracking technologies on our Site. However, some third-party service providers that we may use, such as Google Analytics, may place their own cookies or other tracking technologies on your computer, mobile device, or other devices used to access the Site. We have no control over these third-party technologies and their use is governed by the privacy policies of those third parties.</p>

                <h2>Children Under the Age of 13:</h2>
                <p>Our Site and Service are not intended for children under 13 years of age. No one under age 13 may provide any information to us or use our Site or Service. We do not knowingly collect personal information from children under 13. If we learn that we have collected or received personal information from a child under 13 without verification of parental consent, we will delete that information.</p>

                <h2>Data Security:</h2>
                <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on secure servers behind firewalls. Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal data, we cannot guarantee the security of your data transmitted to our Site; any transmission is at your own risk.</p>

                <h2>Changes to Our Privacy Policy:</h2>
                <p>We may update our Privacy Policy from time to time. If we make material changes to this Policy, we will notify you by email (sent to the email address specified in your account) or by posting a notice on our Site prior to the effective date of the changes. We encourage you to review this Policy periodically for any updates or changes.</p>

                <h2>Contact Information:</h2>
                <p>If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:privacy@bytefort.io">privacy@bytefort.io</a>. If you believe that we have collected or maintained your personal data in violation of this Privacy Policy, please contact us at the same email address to provide us with written details so that we may investigate and attempt to resolve the issue.</p>
    </div>
  );
};

export default PrivacyPolicy;
