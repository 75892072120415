import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../services/AuthContext'; // Import AuthContext

const LoginForm = () => {
    const [formData, setFormData] = useState({
        email: '',
        password: ''
    });
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    const { login } = useContext(AuthContext); // Use useContext to access login method from AuthContext

    const { email, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onSubmit = async e => {
        e.preventDefault();
        setMessage('');

        try {
            await login(email, password); // Use the login method from AuthContext
            navigate('/dashboard'); // Navigate to the dashboard on successful login
        } catch (error) {
            // Handle login failure
            const errorMessage = error.response?.data?.message || error.message || 'Login failed';
            setMessage(errorMessage);
        }
    };

    return (
        <div className='container-body'>
            <h2>Login</h2>
            <form onSubmit={onSubmit}>
                <div>
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        name="email"
                        value={email}
                        onChange={onChange}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Password</label>
                    <input
                        type="password"
                        name="password"
                        value={password}
                        onChange={onChange}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
            {message && <div className='error-message'>{message}</div>}
        </div>
    );
};

export default LoginForm;
