import React from 'react';

function DisplayShortUrl({ shortUrlData, onReset }) {
  return (
    <div className='container-body'>
      <p>Short URL:</p>
      <a href={`${window.location.origin}/${shortUrlData.shortUrl}`} target="_blank" rel="noopener noreferrer">
      {window.location.origin}/{shortUrlData.shortUrl}
      </a>
      <button onClick={onReset}>Shorten Another URL</button>
    </div>
  );
}

export default DisplayShortUrl;
