import React, { useState } from 'react';
import axios from 'axios';
import { useAuth } from '../services/AuthContext'; // Import useAuth

const ChangePasswordForm = () => {
    const [passwords, setPasswords] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
    });
    const [message, setMessage] = useState('');
    const { currentUser } = useAuth();

    const validatePassword = (password) => {
        return password.length >= 8 && /[0-9]/.test(password) && /[A-Z]/.test(password);
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPasswords(prevPasswords => ({
            ...prevPasswords,
            [name]: value
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { oldPassword, newPassword, confirmPassword } = passwords;

        if (newPassword !== confirmPassword) {
            setMessage('New password and confirm password do not match.');
            return;
        }

        if (!validatePassword(newPassword)) {
            setMessage('Password must be at least 8 characters long and include a number and an uppercase letter.');
            return;
        }

        try {
            const response = await axios.post('/api/change-password', {
                oldPassword,
                newPassword
            }, {
                headers: { Authorization: `Bearer ${currentUser?.token}` }
            });

            setMessage(response.data.message || 'Password successfully changed.');
            setPasswords({ oldPassword: '', newPassword: '', confirmPassword: '' });
        } catch (error) {
            setMessage(error.response?.data?.error || 'Failed to change password.');
        }
    };

    return (
        <div className="change-password-form">
            <h2>Change Password</h2>
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="oldPassword">Old Password</label>
                    <input
                        type="password"
                        name="oldPassword"
                        id="oldPassword"
                        value={passwords.oldPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="newPassword">New Password</label>
                    <input
                        type="password"
                        name="newPassword"
                        id="newPassword"
                        value={passwords.newPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm New Password</label>
                    <input
                        type="password"
                        name="confirmPassword"
                        id="confirmPassword"
                        value={passwords.confirmPassword}
                        onChange={handleChange}
                        required
                    />
                </div>
                <button type="submit">Change Password</button>
            </form>
            {message && <p className="message">{message}</p>}
        </div>
    );
};

export default ChangePasswordForm;
