import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from './services/AuthContext';
import ProtectedRoute from './ProtectedRoute';

import UrlShortenerForm from './components/UrlShortenerForm';
import DisplayShortUrl from './components/DisplayShortUrl';
import LoginForm from './components/LoginForm';
import RegistrationForm from './components/RegistrationForm';
import Navbar from './components/Navbar';
import UserDashboard from './components/UserDashboard';
import Footer from './components/Footer';
import './App.css';
import PrivacyPolicy from './components/PrivacyPolicy';

function App() {
  const [shortUrlData, setShortUrlData] = useState(null);

  const handleShortenSuccess = (data) => {
    setShortUrlData(data);
  };

  const resetForm = () => {
    setShortUrlData(null);
  };

  return (
    <AuthProvider> {/* Ensure all components are wrapped in AuthProvider */}
      <Router>
        <div className="App">
          <Navbar /> 
            <Routes>
              <Route path="/" element={
                shortUrlData ? (
                  <DisplayShortUrl shortUrlData={shortUrlData} onReset={resetForm} />
                ) : (
                  <UrlShortenerForm onShortenSuccess={handleShortenSuccess} />
                )
              } />
              <Route path="/login" element={<LoginForm />} />
              <Route path="/register" element={<RegistrationForm />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
              <Route path="/dashboard" element={
                              <ProtectedRoute>
                                  <UserDashboard />
                              </ProtectedRoute>
                          } />
            </Routes>
        </div>
        <div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
