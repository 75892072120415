import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;
const jwtDecode = require('jwt-decode').default;

class AuthService {
    async login(email, password) {
        const response = await axios.post(`${API_URL}/login`, {
            email,
            password
        });
        if (response.data.token) {
            // Make sure the username is part of the response and you're saving it correctly
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    }
    

    logout() {
        localStorage.removeItem('user');
    }
    
    isTokenExpired() {
        const user = this.getCurrentUser();
        if (!user || !user.token) {
            return true;
        }

        try {
            const decoded = jwtDecode(user.token);
            const currentTime = Date.now() / 1000; // to get in milliseconds
            return decoded.exp < currentTime;
        } catch (e) {
            return true;
        }
    }

    async register(username, email, password) {
        const response = await axios.post(`${API_URL}/register`, {
            username,
            email,
            password
        });
        if (response.data.token) {
            // If your register endpoint also returns a token, you might want to log the user in immediately
            localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
    }

    getCurrentUser() {
        const userStr = localStorage.getItem('user');
        if (userStr) return JSON.parse(userStr);
        return null;
    }
}

export default new AuthService();
