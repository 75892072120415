import React from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import LinksList from './LinksList';

const UserDashboard = () => {
    return (
        <div className="user-dashboard">
            <h1>User Dashboard</h1>
            <div className="dashboard-section change-password-section">
                <ChangePasswordForm />
            </div>
            <div className="dashboard-section links-list-section">
                <LinksList />
            </div>
        </div>
    );
};

export default UserDashboard;
