import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

function RegistrationForm() {
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        confirmPassword: '',
    });
    const [message, setMessage] = useState('');
    const [isRegistered, setIsRegistered] = useState(false);

    const { username, email, password, confirmPassword } = formData;

    const onChange = (e) => {
        const value = e.target.name === 'username' ? e.target.value.toLowerCase() : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const validateForm = () => {
        if (!username.match(/^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/)) {
            setMessage('Username must be one word without spaces, and be between 8-12 characters.');
            return false;
        }
        if (!email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
            setMessage('Please enter a valid email address.');
            return false;
        }
        if (password.length < 8 || !password.match(/^(?=.*[0-9])(?=.*[A-Z]).*$/)) {
            setMessage('Password must be at least 8 characters long and include a number and an uppercase letter.');
            return false;
        }
        if (password !== confirmPassword) {
            setMessage('Passwords do not match.');
            return false;
        }
        return true;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        setMessage('');

        if (!validateForm()) {
            return;
        }

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                username,
                email,
                password,
            });
            setMessage(response.data.message || 'Registered successfully. You can now log in.');
            setIsRegistered(true);
        } catch (error) {
            setMessage(error.response?.data?.error || 'An error occurred during registration.');
        }
    };

    return (
        <div className='container-body'>
            <h2>Register</h2>
            {isRegistered ? (
                <div>
                    <p>{message}</p>
                    <Link to="/login">Login</Link> or <Link to="/">Return Home</Link>
                </div>
            ) : (
            <form onSubmit={onSubmit}>
                    <div>
                        <label htmlFor="username">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={username}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="password">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={password}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <input
                            type="password"
                            name="confirmPassword"
                            value={confirmPassword}
                            onChange={onChange}
                            required
                        />
                    </div>
                    <button type="submit">Register</button>
                </form>
            )}
                {message && <div className="error-message">{message}</div>}
        </div>
    );
}

export default RegistrationForm;
