import React, { createContext, useContext, useState, useEffect } from 'react';
import AuthService from './auth.service';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user && !AuthService.isTokenExpired()) {
      setCurrentUser(user);
    } else {
      AuthService.logout();
      setCurrentUser(null);
    }
  }, []);

  const login = async (email, password) => {
    const response = await AuthService.login(email, password);
    if(response.token) {
      setCurrentUser(AuthService.getCurrentUser());
    }
    return response;
  };

  const logout = () => {
    AuthService.logout();
    setCurrentUser(null);
  };

  const value = {
    currentUser,
    login,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export { AuthContext };
