import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../services/AuthContext'; // Import useAuth hook

const API_URL = window.location.origin;

const LinksList = () => {
    const [links, setLinks] = useState([]);
    const [loading, setLoading] = useState(true);
    const { currentUser } = useAuth(); // Use currentUser from AuthContext

    useEffect(() => {
        fetchLinks();
    }, []); // Consider adding currentUser.token as a dependency if tokens can be refreshed

    const fetchLinks = async () => {
        if (!currentUser?.token) return; // Guard clause if not authenticated

        setLoading(true);
        try {
            const response = await axios.get(`${API_URL}/api/user-links`, {
                headers: { Authorization: `Bearer ${currentUser.token}` }, // Use token from currentUser
            });
            setLinks(response.data);
        } catch (error) {
            console.error('Failed to fetch links:', error);
        } finally {
            setLoading(false); // Ensure loading is set to false in finally block
        }
    };

    const handleDeleteLink = async (linkId) => {
        if (!currentUser?.token) return; // Guard clause if not authenticated

        try {
            await axios.delete(`${API_URL}/api/delete-link/${linkId}`, {
                headers: { Authorization: `Bearer ${currentUser.token}` }, // Use token from currentUser
            });
            fetchLinks(); // Refresh the list after deletion
        } catch (error) {
            console.error('Failed to delete link:', error);
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div>
            <h2>My Links</h2>
            {links.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>Original URL</th>
                            <th>Short URL</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {links.map((link) => (
                            <tr key={link._id}>
                                <td>
                                    <a href={link.originalUrl} target="_blank" rel="noopener noreferrer">
                                        {link.originalUrl}
                                    </a>
                                </td>
                                <td>
                                    <a href={`${API_URL}/${link.shortUrl}`} target="_blank" rel="noopener noreferrer">
                                        {`${API_URL}/${link.shortUrl}`}
                                    </a>
                                </td>
                                <td>
                                    <button onClick={() => handleDeleteLink(link._id)}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>No links found.</p>
            )}
        </div>
    );
};

export default LinksList;
