// src/components/Navbar.js
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../services/AuthContext'; // Import useAuth

function Navbar() {
  const { currentUser, logout } = useAuth(); // Use useAuth to access currentUser and logout
  const navigate = useNavigate();

  const onLogout = () => {
    logout(); // Use logout from useAuth
    navigate('/'); // Navigate to the root page
  };

  return (
    <nav>
      <div className="navbar-container">
        <Link to="/" className="navbar-brand">gsgl.ink</Link>
        <div className="navbar-links">
          {currentUser ? (
            <>
              <span className="navbar-text">
                Welcome, <Link to="/dashboard">{currentUser.username || "User"}</Link>!
              </span>
              <button onClick={onLogout} className="navbar-logout-btn">Logout</button>
            </>
          ) : (
            <>
              <Link to="/login" className="navbar-link">Login</Link>
              <Link to="/register" className="navbar-link">Register</Link>
            </>
          )}
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
