import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './services/AuthContext'; // Adjust this path based on your file structure

const ProtectedRoute = ({ children }) => {
    const { currentUser } = useAuth(); // Destructure to get currentUser

    if (!currentUser) {
        // User not authenticated, redirect to login page
        return <Navigate to="/login" replace />;
    }

    // User is authenticated, render the children components
    return children;
};

export default ProtectedRoute;
